import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import "./assets/css/common.css";
import "@/assets/font/font.css";
import 'element-plus/theme-chalk/el-message.css'
import 'vant/es/image-preview/style';

const pinia = createPinia()
const myPlugin = {
    install(app, options) {
        document.dispatchEvent(new Event("custom-render-trigger"));
    }
}

createApp(App).use(router).use(myPlugin).use(pinia).mount('#app')

